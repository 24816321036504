import React from 'react';
import Link from 'gatsby-link'

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.toggleOffers = this.toggleOffers.bind(this);
    this.escFunction = this.escFunction.bind(this);
    if (typeof window !== 'undefined' && window.sessionStorage && sessionStorage.getItem('viewed-offers') === '1') {
      this.state = {
        offersOpen: false
      };
    } else {
      this.state = {
        offersOpen: true
      };
    }
  }
  toggleOffers(e) {
    this.setState({ offersOpen: !this.state.offersOpen });
  }
  escFunction(event){
    if(event.keyCode === 27) {
      this.setState({ offersOpen: false });
    }
  }
  componentDidMount(){
    if (typeof window !== 'undefined' && window.sessionStorage) {
      sessionStorage.setItem('viewed-offers', '1');
    }
    if(window.screen.width < 919){
      this.setState({offersOpen: false})
    }

    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }
  render() {
    return (
      <div className={ this.state.offersOpen ? "open triplesoft-coupon" : "triplesoft-coupon"}>
        <div className="triplesoft-coupon-tab" onClick={this.toggleOffers}>
          <div className="triplesoft-coupon-tab-title">
            COVID-19 RESPONSE
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="16.894" height="25.906" viewBox="0 0 16.894 25.906">
            <path id="Fill_1" data-name="Fill 1" d="M4.327,25.751l12.42-12.425a.545.545,0,0,0,0-.746L4.327.155a.527.527,0,0,0-.746,0L.155,3.583a.527.527,0,0,0,0,.746l8.621,8.625L.155,21.577a.545.545,0,0,0,0,.747l3.427,3.427a.527.527,0,0,0,.746,0" fill="#fff"/>
          </svg>
        </div>
        <div className="triplesoft-coupon-wrapper">
          <div className="triplesoft-coupon-container">
            <h2>See what Scotties<sup>®</sup> is doing to help</h2>
            <Link className="button white-outline" to="/scotties-helps">Get Details</Link>
          </div>

        </div>
      </div>
    )
  }
}

export default Offers;
